export const SHOW_ADD_TO_CART_BTN = true;
export const LIST_CLIENT_ORDERS = true;
export const LIST_REQUISITIONS = true;
export const SHOW_ORDERS_MENU = LIST_CLIENT_ORDERS || LIST_REQUISITIONS;
export const LIST_PROVIDERS = true;
export const SHOW_EDIT_PRODUCT = true;
export const SHOW_BUTTON_CANCEL_ORDER = false;
export const SHOW_ADD_OFFER_BTN = true;
export const SHOW_CREATE_USER = false;
export const SHOW_USERS_LIST = true;
export const SHOW_CANCEL_OFFER_BTN = true;
export const SHOW_ADD_PROVIDER_TO_PRODUCT = true;
export const SHOW_REMOVE_PROVIDER_FROM_PRODUCT = true;
export const SHOW_LIST_ANNOUNCEMENT = true;
export const SHOW_DETAIL_ANNOUNCEMENT = false;
export const SHOW_CREATE_ANNOUNCEMENT = false;
export const SHOW_AUDIT_LOG = true;
